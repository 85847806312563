import {
  ListRideAssessment,
  RideAssessmentOptions,
  RideAssessmentSearch,
  RideAssessmentState
} from '@/pwa/modules/Assessment/types/ride';
import { VueConstructor } from 'vue';
import { ActionTree, Commit, GetterTree, Module, MutationTree } from 'vuex';
import { contains } from '@/pwa/modules/Assessment/helpers/string';
import { isSameDay } from '@/shared/helper/date';
import { SnackbarConfig } from '@/shared/modules/Layout/types';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/stores/layout';
import { AxiosResponse } from 'axios';
import { decamelize } from 'humps';
import { RideAssessment } from '@/shared/modules/AssessmentEdit/types/ride';
import { getRideAssessmentImageIds } from '@/shared/modules/AssessmentEdit/services/ride-assessment-service';
import { deleteAllImagesWithPrefix, deleteImages } from '@/shared/modules/AssessmentEdit/services/image-service';
import {
  convertRideAssessmentFromExport,
  convertRideAssessmentToExport,
  replaceIdsWithImages,
  replaceImagesWithIds
} from '@/shared/modules/AssessmentEdit/services/ride-assessment-conversion';
import { getConfigurationByName } from '@/pwa/modules/Assessment/services/ride-assessment-edit-config';
import { AssessmentEditConfig } from '@/shared/modules/AssessmentEdit/types';

export const RIDE_ASSESSMENT_NAMESPACE = 'RIDE_ASSESSMENT_NAMESPACE';

function showSnackbar(commit: Commit, snackbar: SnackbarConfig) {
  commit(
    `${LAYOUT_NAMESPACE}/showSnackbar`,
    {
      ...snackbar,
      title: `rideAssessment.snackbar.${snackbar.title}`
    },
    { root: true }
  );
}

function showSuccessSnackbar(commit: Commit, title: string, description?: string) {
  showSnackbar(commit, {
    title,
    description,
    icon: 'success'
  });
}

const state: RideAssessmentState = {
  rideAssessmentsLoading: false,
  rideAssessmentsUpdated: null,
  rideAssessmentsUpdateFailed: false,
  rideAssessmentToEdit: undefined,
  rideAssessmentConfig: getConfigurationByName('draft'),
  draftRideAssessments: [],
  synchronizedRideAssessments: [],
  revisionRideAssessments: [],
  draftRideAssessmentSearch: {},
  draftRideAssessmentOptions: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: -1,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['assessmentDateTime'],
    sortDesc: []
  },
  synchronizedRideAssessmentSearch: {
    status: 'synchronized'
  },
  synchronizedRideAssessmentOptions: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: -1,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['assessmentDateTime'],
    sortDesc: []
  },
  synchronizedRideAssessmentsLoading: false,
  revisionRideAssessmentSearch: {
    status: 'rejected'
  },
  revisionRideAssessmentOptions: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: -1,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['assessmentDateTime'],
    sortDesc: []
  },
  revisionRideAssessmentsLoading: false
};

const getters: GetterTree<RideAssessmentState, unknown> = {
  filteredDraftRideAssessments(state): ListRideAssessment[] {
    const {
      lineName,
      trainNumber,
      assessmentDateTime,
      assessmentValidationStatus,
      departureStationCode,
      specialAssessment
    } = state.draftRideAssessmentSearch;
    return state.draftRideAssessments
      .filter(assessment => {
        return (
          (!lineName || contains(assessment.lineName, lineName)) &&
          (!trainNumber || contains(assessment.trainNumber.value, trainNumber)) &&
          (!assessmentDateTime || isSameDay(assessment.assessmentDateTime, assessmentDateTime)) &&
          (!departureStationCode || contains(assessment.departureStationCode, departureStationCode)) &&
          (!assessmentValidationStatus || assessment.assessmentValidationStatus === assessmentValidationStatus) &&
          (undefined === specialAssessment || assessment.specialAssessment === specialAssessment)
        );
      })
      .map(assessment => {
        return {
          assessmentId: assessment.assessmentId,
          assessmentDateTime: assessment.assessmentDateTime,
          lineName: assessment.lineName,
          assessmentValidationStatus: assessment.assessmentValidationStatus,
          trainNumber: assessment.trainNumber.value,
          departureStationCode: assessment.departureStationCode,
          isSynchronizing: assessment.isSynchronizing,
          specialAssessment: assessment.specialAssessment,
          status: 'draft'
        };
      });
  }
};

const mutations: MutationTree<RideAssessmentState> = {
  setRideAssessmentToEdit(state, payload) {
    state.rideAssessmentToEdit = payload;
  },
  setDraftRideAssessmentSearch(state, payload: RideAssessmentSearch) {
    state.draftRideAssessmentSearch = payload;
  },
  setDraftRideAssessmentOptions(state, payload: RideAssessmentOptions) {
    state.draftRideAssessmentOptions = payload;
  },
  setSynchronizedRideAssessments(state, payload: ListRideAssessment[]) {
    state.synchronizedRideAssessments = payload;
  },
  setSynchronizedRideAssessmentSearch(state, payload: RideAssessmentSearch) {
    state.synchronizedRideAssessmentSearch = payload;
  },
  setSynchronizedRideAssessmentOptions(state, payload: RideAssessmentOptions) {
    state.synchronizedRideAssessmentOptions = payload;
  },
  setSynchronizedRideAssessmentsLoading(state, payload: boolean) {
    state.synchronizedRideAssessmentsLoading = payload;
  },
  setRevisionRideAssessments(state, payload: ListRideAssessment[]) {
    state.revisionRideAssessments = payload;
  },
  setRevisionRideAssessmentSearch(state, payload: RideAssessmentSearch) {
    state.revisionRideAssessmentSearch = payload;
  },
  setRevisionRideAssessmentOptions(state, payload: RideAssessmentOptions) {
    state.revisionRideAssessmentOptions = payload;
  },
  setRevisionRideAssessmentsLoading(state, payload: boolean) {
    state.revisionRideAssessmentsLoading = payload;
  },
  addDraftRideAssessment(state, payload: RideAssessment) {
    state.draftRideAssessments.push(payload);
  },
  deleteDraftRideAssessment(state, payload: { assessmentId: string }) {
    state.draftRideAssessments = state.draftRideAssessments.filter(
      assessment => assessment.assessmentId !== payload.assessmentId
    );
  },
  setRideAssessmentIsSynchronizing(state, payload: { assessmentId: string; isSynchronizing: boolean }) {
    const { assessmentId, isSynchronizing } = payload;
    const assessment = state.draftRideAssessments.find(assessment => assessment.assessmentId === assessmentId);
    if (assessment) {
      assessment.isSynchronizing = isSynchronizing;
    }
  },
  setRideAssessmentConfig(state, payload: AssessmentEditConfig) {
    state.rideAssessmentConfig = {
      ...payload
    };
  }
};

const configureActions: (Vue: VueConstructor) => ActionTree<RideAssessmentState, unknown> = Vue => {
  const actions: ActionTree<RideAssessmentState, unknown> = {
    async fetchRideAssessment({ commit }, id) {
      commit('setRideAssessmentToEdit', undefined);

      try {
        const response: AxiosResponse = await Vue.prototype.$http.get('ride/assessment/get', {
          params: {
            assessmentId: id
          }
        });
        await deleteAllImagesWithPrefix('ride-assessment-edit-');
        const assessment = convertRideAssessmentFromExport(response?.data);
        await replaceImagesWithIds(assessment);
        commit('setRideAssessmentToEdit', assessment);
      } catch (error) {
        console.error(error);
        showSnackbar(commit, { title: 'fetchError', icon: 'error', color: 'error' });
      }
    },
    async updateOnlineRideAssessment({ commit }, assessment: RideAssessment) {
      try {
        const exportRideAssessment = convertRideAssessmentToExport(assessment);
        await replaceIdsWithImages(exportRideAssessment);

        await Vue.prototype.$http.post('commands/ride/assessment/update', exportRideAssessment);
        showSnackbar(commit, { title: 'updateSuccess' });
      } catch (error) {
        console.error(error);
        showSnackbar(commit, { title: 'updateError', icon: 'error', color: 'error' });
      }
    },
    async fetchSynchronizedRideAssessments({ state, commit }) {
      commit('setSynchronizedRideAssessmentsLoading', true);
      try {
        const response: AxiosResponse = await Vue.prototype.$http.get('ride/assessment/list', {
          params: {
            search: Object.fromEntries(
              Object.entries(state.synchronizedRideAssessmentSearch).filter(([, value]) => value !== null)
            ),
            sortBy: decamelize(state.synchronizedRideAssessmentOptions.sortBy[0]),
            sorting: state.synchronizedRideAssessmentOptions.sortDesc[0] ? 'desc' : 'asc'
          }
        });
        const rideAssessments: ListRideAssessment[] = response.data.items;
        commit('setSynchronizedRideAssessments', rideAssessments);
      } catch (error) {
        console.error(error);
      } finally {
        commit('setSynchronizedRideAssessmentsLoading', false);
      }
    },
    async fetchRevisionRideAssessments({ state, commit }) {
      commit('setRevisionRideAssessmentsLoading', true);
      try {
        const response: AxiosResponse = await Vue.prototype.$http.get('ride/assessment/list', {
          params: {
            search: Object.fromEntries(
              Object.entries(state.revisionRideAssessmentSearch).filter(([, value]) => value !== null)
            ),
            sortBy: decamelize(state.revisionRideAssessmentOptions.sortBy[0]),
            sorting: state.revisionRideAssessmentOptions.sortDesc[0] ? 'desc' : 'asc'
          }
        });
        const rideAssessments: ListRideAssessment[] = response.data.items;
        commit('setRevisionRideAssessments', rideAssessments);
      } catch (error) {
        console.error(error);
      } finally {
        commit('setRevisionRideAssessmentsLoading', false);
      }
    },
    async synchronizeDraftRideAssessment({ commit, state, dispatch }, listRideAssessment: ListRideAssessment) {
      try {
        commit('setRideAssessmentIsSynchronizing', {
          assessmentId: listRideAssessment.assessmentId,
          isSynchronizing: true
        });

        const rideAssessment = state.draftRideAssessments.find(
          assessment => assessment.assessmentId === listRideAssessment.assessmentId
        );
        if (rideAssessment === undefined) {
          return;
        }

        const exportRideAssessment = convertRideAssessmentToExport(rideAssessment);
        await replaceIdsWithImages(exportRideAssessment);

        await Vue.prototype.$http.post('commands/ride/assessment/add', exportRideAssessment);
        showSnackbar(commit, { title: 'synchronizeSuccess' });

        dispatch('fetchSynchronizedRideAssessments');
        dispatch('deleteDraftRideAssessment', rideAssessment.assessmentId);
      } catch (error) {
        console.error(error);
        showSnackbar(commit, { title: 'synchronizeError', color: 'error', icon: 'error' });
      } finally {
        commit('setRideAssessmentIsSynchronizing', {
          assessmentId: listRideAssessment.assessmentId,
          isSynchronizing: false
        });
      }
    },
    async deleteDraftRideAssessment({ state, commit }, id: string) {
      const rideAssessment = state.draftRideAssessments.find(assessment => assessment.assessmentId === id);
      if (rideAssessment === undefined) {
        return;
      }
      const rideAssessmentImageIds = getRideAssessmentImageIds(rideAssessment);
      commit('deleteDraftRideAssessment', { assessmentId: id });
      await deleteImages(rideAssessmentImageIds);
    },
    async deleteOnlineRideAssessment({ commit, dispatch }, id: string) {
      try {
        await Vue.prototype.$http.post('commands/ride/assessment/remove', {
          assessmentId: id
        });
        showSnackbar(commit, { title: 'deleteSuccess' });
        dispatch('fetchSynchronizedRideAssessments');
        dispatch('fetchRevisionRideAssessments');
      } catch (error) {
        console.error(error);
        showSnackbar(commit, { title: 'deleteError', color: 'error', icon: 'error' });
      }
    },
    async reviseRevisionRideAssessment({ commit, dispatch }, id: string) {
      try {
        await Vue.prototype.$http.post('commands/ride/assessment/revise', { assessmentId: id });
        showSnackbar(commit, { title: 'reviseSuccess' });
        dispatch('fetchSynchronizedRideAssessments');
        dispatch('fetchRevisionRideAssessments');
      } catch (error) {
        console.error(error);
        showSnackbar(commit, { title: 'reviseError', color: 'error', icon: 'error' });
      }
    },
    async exportRideAssessments({ commit }, assessmentIds: string[]) {
      try {
        const response: AxiosResponse = await Vue.prototype.$http.post('commands/ride/assessment/export', {
          assessmentIds
        });
        if (response.status !== 202) {
          return;
        }
        const csvResponse: AxiosResponse<Blob> = await Vue.prototype.$http.get('ride/assessment/csv', {
          params: {
            assessmentIds
          },
          responseType: 'blob'
        });
        if (csvResponse.status === 204) {
          return;
        }
        if (csvResponse.status !== 200) {
          return;
        }
        return csvResponse.data;
      } catch (error) {
        console.error(error);
        showSnackbar(commit, { title: 'exportError', color: 'error', icon: 'error' });
      }
    },
    showRideAssessmentCompleteSnackbar({ commit }) {
      showSuccessSnackbar(commit, 'rideAssessmentComplete');
    },
    async setSynchronizedRideAssessmentSearch({ commit, dispatch }, payload: RideAssessmentSearch) {
      commit('setSynchronizedRideAssessmentSearch', payload);
      dispatch('fetchSynchronizedRideAssessments');
    },
    async setSynchronizedRideAssessmentOptions({ commit, dispatch }, payload: RideAssessmentOptions) {
      commit('setSynchronizedRideAssessmentOptions', payload);
      dispatch('fetchSynchronizedRideAssessments');
    },
    async setRevisionRideAssessmentSearch({ commit, dispatch }, payload: RideAssessmentSearch) {
      commit('setRevisionRideAssessmentSearch', payload);
      dispatch('fetchRevisionRideAssessments');
    },
    async setRevisionRideAssessmentOptions({ commit, dispatch }, payload: RideAssessmentOptions) {
      commit('setRevisionRideAssessmentOptions', payload);
      dispatch('fetchRevisionRideAssessments');
    }
  };
  return actions;
};

export const configureRideAssessmentStore = (Vue: VueConstructor) => {
  const module: Module<RideAssessmentState, unknown> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions: configureActions(Vue)
  };
  return module;
};
