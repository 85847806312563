import { format, isSameDay as sameDay, parse, parseISO, startOfQuarter, endOfQuarter, min, isAfter } from 'date-fns';

const HOUR_MINUTE_REGEX = /\d\d:\d\d/;

function formatDate(value: string | null): string {
  return (value && format(parseISO(value), 'dd.MM.yyyy')) || '';
}

function formatDateTime(value: string | null): string {
  return (value && format(parseISO(value), 'dd.MM.yyyy HH:mm:ss')) || '';
}

function formatDateTimeShort(value: string | null): string {
  return (value && format(parseISO(value), 'dd.MM.yyyy HH:mm')) || '';
}

function formatApiDateTime(value: string | null): string {
  return (value && format(parseISO(value), 'yyyy-MM-dd HH:mm:ss')) || '';
}

function formatPickerDate(value: number | Date | undefined): string {
  return (value && format(value, 'yyyy-MM-dd')) || '';
}

function parseApiDateTime(value: string | null): string | undefined {
  return (value && parse(value, 'yyyy-MM-dd HH:mm:ss', new Date()).toISOString()) || undefined;
}

function isSameDay(firstDate: string | undefined, secondDate: string | undefined) {
  return firstDate !== undefined && secondDate !== undefined && sameDay(parseISO(firstDate), parseISO(secondDate));
}

function isFutureDate(value: string | null): boolean {
  return value !== null && isAfter(parseISO(value), new Date());
}

function formatImageDownloadDateTime(value: string | null): string {
  return (value && format(parseISO(value), 'yyyyMMdd')) || '';
}

function getStartOfQuarter(date: string | undefined) {
  return (date && startOfQuarter(new Date(date))) || undefined;
}

function getEndOfQuarter(date: string | undefined) {
  return (date && endOfQuarter(new Date(date))) || undefined;
}

function getHoursAndMinutesFromApiDate(value: string): string {
  return value.match(HOUR_MINUTE_REGEX)?.[0] ?? '';
}

function getApiDateFromHoursAndMinutes(value: string): string {
  if (!value) {
    return '';
  }

  const currentApiDate = formatApiDateTime(new Date().toISOString());
  return HOUR_MINUTE_REGEX.test(value) ? `${currentApiDate.substring(0, 11)}${value}:00` : currentApiDate;
}

export {
  formatDate,
  formatDateTime,
  isSameDay,
  isFutureDate,
  formatApiDateTime,
  formatDateTimeShort,
  formatImageDownloadDateTime,
  formatPickerDate,
  parseApiDateTime,
  getStartOfQuarter,
  getEndOfQuarter,
  getApiDateFromHoursAndMinutes,
  getHoursAndMinutesFromApiDate,
  min,
  isAfter
};
