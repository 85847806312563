<template>
  <v-container class="pt-0 px-6">
    <section-heading subheading="rideAssessmentEdit.sections.comment" />
    <div class="form-grid">
      <div class="section-error-wrapper">
        <section-error :show="rideAssessment.forms.assessmentComment.status === 'incomplete'">
          {{ $t('rideAssessmentEdit.error.assessmentComment') }}
        </section-error>
      </div>
      <v-checkbox
        :input-value="rideAssessment.assessmentComment.noComment"
        :label="$t('rideAssessmentEdit.devaluationUnits.noComment')"
        :disabled="readOnly"
        dense
        @change="updateValue('noComment', $event)"
      ></v-checkbox>
      <devaluation-input
        title="rideAssessmentEdit.devaluationUnits.comment"
        :has-comment="commentDevaluationInputHasComment"
        :has-image="commentDevaluationInputHasImage"
        @open-modal="cameraAndNotesModalVisible = true"
      >
        <section-note-input :value="rideAssessment.assessmentComment.note" />
      </devaluation-input>
    </div>
    <assessment-overview-button
      :complete="rideAssessment.forms.assessmentComment.status === 'complete'"
      to-route-name="ride-assessment"
    />
    <camera-and-notes-modal
      v-if="cameraAndNotesModalVisible"
      devaluation-unit-name="assessmentComment"
      :title="cameraAndNotesModalTitle"
      :note="rideAssessment.assessmentComment.note"
      :images="cameraAndNotesModalImages"
      :download-title="cameraAndNotesModalDownloadTitle"
      :read-only="readOnly || rideAssessment.assessmentComment.deactivated"
      @add-note="updateValue('note', $event)"
      @add-images="addImages"
      @delete-image="deleteImage"
      @closed="cameraAndNotesModalVisible = false"
    ></camera-and-notes-modal>
  </v-container>
</template>

<script lang="ts">
import { mapActions, mapState } from 'vuex';
import { PageLeaveMixin } from '@/shared/modules/AssessmentEdit/mixins/page-leave-mixin';
import { RIDE_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/ride-assessment-edit';
import { RideAssessmentEditState } from '@/shared/modules/AssessmentEdit/types/ride';
import AssessmentOverviewButton from '@/shared/modules/AssessmentEdit/components/AssessmentOverviewButton.vue';
import CameraAndNotesModal from '@/shared/modules/AssessmentEdit/components/CameraAndNotesModal.vue';
import DevaluationInput from '@/shared/modules/AssessmentEdit/components/DevaluationInput.vue';
import SectionError from '@/shared/modules/AssessmentEdit/components/SectionError.vue';
import SectionHeading from '@/shared/modules/AssessmentEdit/components/SectionHeading.vue';
import Vue, { VueConstructor } from 'vue';
import { getImageDownloadTitle } from '@/shared/modules/AssessmentEdit/services/ride-assessment-service';
import { getImages, getImagesFromFileList } from '@/shared/modules/AssessmentEdit/services/image-service';
import SectionNoteInput from '@/shared/modules/AssessmentEdit/components/SectionNoteInput.vue';

type VuexBindings = {
  rideAssessment: RideAssessmentEditState['rideAssessment'];
  readOnly: RideAssessmentEditState['readOnly'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  mixins: [PageLeaveMixin('setSectionVisited')],
  components: {
    SectionError,
    SectionHeading,
    DevaluationInput,
    AssessmentOverviewButton,
    CameraAndNotesModal,
    SectionNoteInput
  },
  data() {
    return {
      cameraAndNotesModalVisible: false
    };
  },
  computed: {
    ...mapState(RIDE_ASSESSMENT_EDIT_NAMESPACE, ['rideAssessment', 'readOnly']),
    cameraAndNotesModalTitle(): string {
      return 'rideAssessmentEdit.devaluationUnits.comment';
    },
    cameraAndNotesModalDownloadTitle(): string {
      return getImageDownloadTitle(
        this.rideAssessment.lineName,
        this.rideAssessment.assessmentDateTime,
        this.$t(this.cameraAndNotesModalTitle).toString()
      );
    },
    commentDevaluationInputHasComment(): boolean {
      return this.rideAssessment.assessmentComment.note !== '';
    },
    commentDevaluationInputHasImage(): boolean {
      return this.rideAssessment.assessmentComment.imageIds.length > 0;
    }
  },
  methods: {
    ...mapActions(RIDE_ASSESSMENT_EDIT_NAMESPACE, [
      'updateRideAssessmentValue',
      'addRideAssessmentImages',
      'deleteRideAssessmentImage'
    ]),
    updateValue(property: string, value: unknown) {
      this.updateRideAssessmentValue({ path: `assessmentComment.${property}`, value });
    },
    async addImages(event: Event) {
      const images = await getImagesFromFileList(event);
      await this.addRideAssessmentImages({ path: 'assessmentComment.imageIds', images });
    },
    async deleteImage(imageIndex: number) {
      this.deleteRideAssessmentImage({
        path: 'assessmentComment.imageIds',
        imageIndex
      });
    },
    setSectionVisited() {
      this.updateRideAssessmentValue({ path: `forms.assessmentComment.visited`, value: true });
    }
  },
  asyncComputed: {
    cameraAndNotesModalImages: {
      get(): Promise<string[]> {
        return getImages(this.rideAssessment.assessmentComment.imageIds);
      },
      default: []
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/layout.scss';

.form-grid {
  --row-gap: 16px;
  @include dynamic-grid(40px, 300px, 3);
  // set negative margin to compensate for the row gap when the section error is not shown
  margin-block-start: calc(-1 * var(--row-gap));

  @include md-and-up {
    --row-gap: 40px;
    row-gap: var(--row-gap);
    justify-content: start;
  }
}

.section-error-wrapper {
  grid-column: 1 / -1;
  position: sticky;
  top: 80px;
  z-index: 1;
}
</style>
