<template>
  <v-container class="pt-0 px-6">
    <section-heading subheading="rideAssessmentEdit.sections.baseData" />
    <div class="form-grid">
      <v-text-field
        :label="$t('rideAssessmentEdit.devaluationUnits.trainNumber')"
        :value="rideAssessment.trainNumber.value"
        :disabled="readOnly"
        :error-messages="getErrorMessage(rideAssessment.trainNumber.errorMessage)"
        type="number"
        inputmode="numeric"
        pattern="[0-9]*"
        data-test="input-train-number"
        hide-spin-buttons
        @change="updateValue('trainNumber.value', $event)"
      />
      <dialog-select
        :label="$t('rideAssessmentEdit.devaluationUnits.departureStation')"
        :value="rideAssessment.departureStationId.value"
        :items="stationsOnLine"
        :disabled="readOnly"
        :error-messages="getErrorMessage(rideAssessment.departureStationId.errorMessage)"
        item-text="name"
        item-value="id"
        data-test="select-departure-station"
        @input="updateDepartureStation"
      />
      <app-time-picker
        :value="departureTimeNominal"
        :label="$t('rideAssessmentEdit.devaluationUnits.departureTimeNominal')"
        :max="tomorrow"
        :disabled="readOnly"
        :error-messages="getErrorMessage(rideAssessment.departureTimeNominal.errorMessage)"
        :resettable="true"
        data-test="input-departure-time-nominal"
        @input="updateValue('departureTimeNominal.value', getHoursAndMinutesFromApiDate($event))"
      />
      <app-time-picker
        :value="departureTimeActual"
        :label="$t('rideAssessmentEdit.devaluationUnits.departureTimeActual')"
        :max="tomorrow"
        :disabled="readOnly"
        :error-messages="getErrorMessage(rideAssessment.departureTimeActual.errorMessage)"
        :resettable="true"
        data-test="input-departure-time-actual"
        @input="updateValue('departureTimeActual.value', getHoursAndMinutesFromApiDate($event))"
      />

      <devaluation-input
        :has-comment="cancelledDevaluationInputHasComment"
        :has-image="cancelledDevaluationInputHasImage"
        :error-message="rideAssessment.cancelled.errorMessage"
        inline
        @open-modal="cameraAndNotesModalVisible = true"
      >
        <v-checkbox
          :input-value="rideAssessment.cancelled.value"
          :label="$t('rideAssessmentEdit.devaluationUnits.cancelled')"
          :disabled="readOnly"
          hide-details
          dense
          @change="updateValue('cancelled.value', $event)"
        ></v-checkbox>
      </devaluation-input>

      <dialog-select
        :label="$t('rideAssessmentEdit.devaluationUnits.arrivalStation')"
        :value="rideAssessment.arrivalStationId.value"
        :items="stationsOnLine"
        :disabled="readOnly"
        :error-messages="getErrorMessage(rideAssessment.arrivalStationId.errorMessage)"
        item-text="name"
        item-value="id"
        data-test="select-arrival-station"
        @input="updateValue('arrivalStationId.value', $event)"
      />
      <app-time-picker
        :value="arrivalTimeNominal"
        :label="$t('rideAssessmentEdit.devaluationUnits.arrivalTimeNominal')"
        :max="tomorrow"
        :disabled="readOnly"
        :error-messages="getErrorMessage(rideAssessment.arrivalTimeNominal.errorMessage)"
        :resettable="true"
        data-test="input-arrival-time-nominal"
        @input="updateValue('arrivalTimeNominal.value', getHoursAndMinutesFromApiDate($event))"
      />
      <app-time-picker
        :value="arrivalTimeActual"
        :label="$t('rideAssessmentEdit.devaluationUnits.arrivalTimeActual')"
        :max="tomorrow"
        :disabled="readOnly || rideAssessment.arrivalTimeActual.deactivated"
        :error-messages="getErrorMessage(rideAssessment.arrivalTimeActual.errorMessage)"
        :resettable="true"
        data-test="input-arrival-time-actual"
        @input="updateValue('arrivalTimeActual.value', getHoursAndMinutesFromApiDate($event))"
      />
    </div>
    <assessment-overview-button
      :complete="rideAssessment.forms.baseData.status === 'complete'"
      to-route-name="ride-assessment"
    />
    <camera-and-notes-modal
      v-if="cameraAndNotesModalVisible"
      devaluation-unit-name="cancellation"
      :title="cameraAndNotesModalTitle"
      :note="rideAssessment.cancellationComment.note"
      :images="cameraAndNotesModalImages"
      :download-title="cameraAndNotesModalDownloadTitle"
      :read-only="readOnly"
      @add-note="updateValue('cancellationComment.note', $event)"
      @add-images="addImages"
      @delete-image="deleteImage"
      @closed="cameraAndNotesModalVisible = false"
    ></camera-and-notes-modal>
  </v-container>
</template>

<script lang="ts">
import { mapActions, mapState } from 'vuex';
import { PageLeaveMixin } from '@/shared/modules/AssessmentEdit/mixins/page-leave-mixin';
import { RIDE_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/ride-assessment-edit';
import { RideAssessmentEditState } from '@/shared/modules/AssessmentEdit/types/ride';
import AssessmentOverviewButton from '@/shared/modules/AssessmentEdit/components/AssessmentOverviewButton.vue';
import CameraAndNotesModal from '@/shared/modules/AssessmentEdit/components/CameraAndNotesModal.vue';
import DevaluationInput from '@/shared/modules/AssessmentEdit/components/DevaluationInput.vue';
import SectionHeading from '@/shared/modules/AssessmentEdit/components/SectionHeading.vue';
import Vue, { VueConstructor } from 'vue';
import { getImageDownloadTitle } from '@/shared/modules/AssessmentEdit/services/ride-assessment-service';
import { getImages, getImagesFromFileList } from '@/shared/modules/AssessmentEdit/services/image-service';
import { Station } from '@/shared/plugins/database/profitester';
import AppTimePicker from '@/shared/modules/Global/components/AppTimePicker.vue';
import { getHoursAndMinutesFromApiDate, getApiDateFromHoursAndMinutes } from '@/shared/helper/date';
import { ErrorMessage } from '@/shared/modules/AssessmentEdit/types';
import { dbHelper } from '@/shared/helper/database';
import DialogSelect from '@/shared/modules/AssessmentEdit/components/DialogSelect.vue';

type VuexBindings = {
  rideAssessment: RideAssessmentEditState['rideAssessment'];
  readOnly: RideAssessmentEditState['readOnly'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  mixins: [PageLeaveMixin('setSectionVisited')],
  components: {
    DialogSelect,
    SectionHeading,
    DevaluationInput,
    AssessmentOverviewButton,
    CameraAndNotesModal,
    AppTimePicker
  },
  data() {
    return {
      cameraAndNotesModalVisible: false,
      stationsOnLine: [] as Station[],
      tomorrow: new Date(Date.now() + 24 * 60 * 60 * 1000)
    };
  },
  computed: {
    ...mapState(RIDE_ASSESSMENT_EDIT_NAMESPACE, ['rideAssessment', 'readOnly']),
    cameraAndNotesModalTitle(): string {
      return 'rideAssessmentEdit.devaluationUnits.cancelled';
    },
    cameraAndNotesModalDownloadTitle(): string {
      return getImageDownloadTitle(
        this.rideAssessment.lineName,
        this.rideAssessment.assessmentDateTime,
        this.$t(this.cameraAndNotesModalTitle).toString()
      );
    },
    cancelledDevaluationInputHasComment(): boolean {
      return this.rideAssessment.cancellationComment.note !== '';
    },
    cancelledDevaluationInputHasImage(): boolean {
      return this.rideAssessment.cancellationComment.imageIds.length > 0;
    },
    departureTimeNominal(): string {
      return getApiDateFromHoursAndMinutes(this.rideAssessment.departureTimeNominal.value);
    },
    arrivalTimeNominal(): string {
      return getApiDateFromHoursAndMinutes(this.rideAssessment.arrivalTimeNominal.value);
    },
    departureTimeActual(): string {
      return getApiDateFromHoursAndMinutes(this.rideAssessment.departureTimeActual.value);
    },
    arrivalTimeActual(): string {
      return getApiDateFromHoursAndMinutes(this.rideAssessment.arrivalTimeActual.value);
    }
  },
  methods: {
    ...mapActions(RIDE_ASSESSMENT_EDIT_NAMESPACE, [
      'updateRideAssessmentValue',
      'addRideAssessmentImages',
      'deleteRideAssessmentImage'
    ]),
    getHoursAndMinutesFromApiDate,
    updateValue(property: string, value: unknown) {
      this.updateRideAssessmentValue({ path: property, value });
    },
    updateDepartureStation(stationId: string) {
      this.rideAssessment.departureStationCode = '';

      if (stationId) {
        const departureStationCode = this.stationsOnLine.find(station => station.id === stationId)?.code;
        if (departureStationCode !== undefined) {
          this.rideAssessment.departureStationCode = departureStationCode;
        }
      }

      this.updateRideAssessmentValue({ path: 'departureStationId.value', value: stationId });
    },
    async addImages(event: Event) {
      const images = await getImagesFromFileList(event);
      await this.addRideAssessmentImages({ path: 'cancellationComment.imageIds', images });
    },
    async deleteImage(imageIndex: number) {
      this.deleteRideAssessmentImage({
        path: 'cancellationComment.imageIds',
        imageIndex
      });
    },
    setSectionVisited() {
      this.updateRideAssessmentValue({ path: `forms.baseData.visited`, value: true });
    },
    getErrorMessage(errorMessage: ErrorMessage | undefined): string[] | undefined {
      const errorMessageTranslationKey = `assessmentEdit.devaluationErrors.${errorMessage}`;
      return errorMessageTranslationKey === undefined || !this.$te(errorMessageTranslationKey)
        ? undefined
        : [this.$t(errorMessageTranslationKey).toString()];
    }
  },
  asyncComputed: {
    cameraAndNotesModalImages: {
      get(): Promise<string[]> {
        return getImages(this.rideAssessment.cancellationComment.imageIds);
      },
      default: []
    }
  },
  async mounted() {
    this.stationsOnLine = await dbHelper.getSortedStationsOnLine(this.rideAssessment.lineId);
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/layout.scss';

.form-grid {
  @include dynamic-grid(40px, 300px, 3);

  @include md-and-up {
    row-gap: 40px;
    justify-content: start;
  }
}
</style>
