import Vue, { VueConstructor } from 'vue';
import {
  DevaluationUnit,
  DevaluationUnitName,
  DevaluationUnitSectionName,
  RideAssessmentEditState
} from '@/shared/modules/AssessmentEdit/types/ride';
import BooleanOption from '@/shared/modules/AssessmentEdit/components/BooleanOption.vue';
import NumberRangeOption from '@/shared/modules/AssessmentEdit/components/NumberRangeOption.vue';
import CountSelectOption from '@/shared/modules/AssessmentEdit/components/CountSelectOption.vue';
import { mapActions, mapState } from 'vuex';
import { RIDE_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/ride-assessment-edit';
import { getDevaluationUnitInformation } from '@/shared/modules/AssessmentEdit/services/ride-devaluation-unit-information';
import { getImages, getImagesFromFileList } from '@/shared/modules/AssessmentEdit/services/image-service';
import { PageLeaveMixin } from '@/shared/modules/AssessmentEdit/mixins/page-leave-mixin';
import { getImageDownloadTitle } from '@/shared/modules/AssessmentEdit/services/ride-assessment-service';
import { NoImagesAllowedDevaluationUnits } from '@/shared/modules/AssessmentEdit/services/ride-assessment-statics';
import { includes } from '@/shared/helper/typescript';
import { ErrorMessage } from '@/shared/modules/AssessmentEdit/types';

type VuexBindings = {
  rideAssessment: RideAssessmentEditState['rideAssessment'];
};

type DevaluationInput = {
  key: DevaluationUnitName;
  title: string;
  type: string;
  component: string;
  showInfo: boolean;
  value: unknown;
  hasComment: boolean;
  hasImage: boolean;
  disabled: boolean;
  errorMessage?: ErrorMessage;
};

const RideFormMixin = function (sectionName: DevaluationUnitSectionName) {
  return (Vue as VueConstructor<Vue & VuexBindings>).extend({
    mixins: [PageLeaveMixin('setSectionVisited')],
    components: {
      BooleanOption,
      NumberRangeOption,
      CountSelectOption
    },
    data() {
      return {
        cameraAndNotesModalDevaluationUnitKey: '' as DevaluationUnitName | '',
        devaluationInformationModalVisible: false,
        devaluationInformationModalTitle: '',
        devaluationInformationModalText: ''
      };
    },
    computed: {
      ...mapState(RIDE_ASSESSMENT_EDIT_NAMESPACE, ['rideAssessment', 'readOnly']),
      devaluationInputs(): DevaluationInput[] {
        return this.rideAssessment[sectionName].map(
          item =>
            ({
              key: item.name,
              title: `rideAssessmentEdit.devaluationUnits.${item.name}`,
              type: item.type,
              component: this.getDevaluationInputComponent(item.type),
              showInfo: getDevaluationUnitInformation(item.name, this.rideAssessment.scaleType) !== undefined,
              value: item.value,
              hasComment: item.note !== '' || item.predefinedNotes.length > 0,
              hasImage: item.imageIds.length > 0,
              disabled: this.readOnly || item.deactivated,
              errorMessage: item.errorMessage
            } satisfies DevaluationInput)
        );
      },
      cameraAndNotesModalDevaluationUnit(): DevaluationUnit | undefined {
        return this.rideAssessment[sectionName].find(item => item.name === this.cameraAndNotesModalDevaluationUnitKey);
      },
      cameraAndNotesModalTitle(): string {
        return `rideAssessmentEdit.devaluationUnits.${this.cameraAndNotesModalDevaluationUnitKey}`;
      },
      cameraAndNotesModalNoImagesAllowed(): boolean {
        return includes(NoImagesAllowedDevaluationUnits, this.cameraAndNotesModalDevaluationUnitKey) ? true : false;
      },
      cameraAndNotesModalReadOnly(): boolean {
        return this.readOnly || this.cameraAndNotesModalDevaluationUnit?.deactivated === true;
      },
      cameraAndNotesModalDownloadTitle(): string {
        return getImageDownloadTitle(
          this.rideAssessment.lineName,
          this.rideAssessment.assessmentDateTime,
          this.$t(this.cameraAndNotesModalTitle).toString()
        );
      },
      sectionIsComplete(): boolean {
        return this.rideAssessment.forms[sectionName].status === 'complete';
      }
    },
    methods: {
      getDevaluationInputComponent(type: string) {
        switch (type) {
          case 'boolean':
            return 'BooleanOption';
          case 'range_na_0_75_100':
            return 'NumberRangeOption';
          case 'range_na_0_75_90_100':
            return 'NumberRangeOption';
          case 'range_na_0_25_75_100':
            return 'NumberRangeOption';
          case 'range_na_0_50_100':
            return 'NumberRangeOption';
          case 'range_na_50_75_100':
            return 'NumberRangeOption';
          case 'range_na_0_100':
            return 'NumberRangeOption';
          case 'boolean_na_Yes_No':
            return 'NumberRangeOption';
          case 'boolean_na_No_Yes':
            return 'NumberRangeOption';
          case 'number_na_6':
            return 'CountSelectOption';
          default:
            return '';
        }
      },
      openInfoModal(devaluationUnitKey: DevaluationUnitName) {
        this.devaluationInformationModalVisible = true;
        this.devaluationInformationModalTitle = `rideAssessmentEdit.devaluationUnits.${devaluationUnitKey}`;
        this.devaluationInformationModalText =
          getDevaluationUnitInformation(devaluationUnitKey, this.rideAssessment.scaleType) ?? '';
      },
      openCameraAndNotesModal(key: DevaluationUnitName) {
        this.cameraAndNotesModalDevaluationUnitKey = key;
      },
      ...mapActions(RIDE_ASSESSMENT_EDIT_NAMESPACE, [
        'updateRideAssessmentValue',
        'addRideAssessmentImages',
        'deleteRideAssessmentImage'
      ]),
      updateValue(key: string, value: unknown, property = 'value') {
        const index = this.rideAssessment[sectionName].findIndex(item => item.name === key);
        const keyPath = `${sectionName}[${index}].${property}`;
        this.updateRideAssessmentValue({ path: keyPath, value });
      },
      async addImages(event: Event) {
        const images = await getImagesFromFileList(event);
        const index = this.rideAssessment[sectionName].findIndex(
          item => item.name === this.cameraAndNotesModalDevaluationUnitKey
        );
        await this.addRideAssessmentImages({ path: `${sectionName}[${index}].imageIds`, images });
      },
      async deleteImage(imageIndex: number) {
        const index = this.rideAssessment[sectionName].findIndex(
          item => item.name === this.cameraAndNotesModalDevaluationUnitKey
        );
        this.deleteRideAssessmentImage({
          path: `${sectionName}[${index}].imageIds`,
          imageIndex
        });
      },
      setSectionVisited() {
        this.updateRideAssessmentValue({ path: `forms.${sectionName}.visited`, value: true });
      }
    },
    asyncComputed: {
      cameraAndNotesModalImages: {
        get(): Promise<string[]> {
          if (this.cameraAndNotesModalDevaluationUnit === undefined) return Promise.resolve([]);
          return getImages(this.cameraAndNotesModalDevaluationUnit.imageIds);
        },
        default: []
      }
    }
  });
};

type RideFormMixinType = InstanceType<ReturnType<typeof RideFormMixin>>;

export { RideFormMixin, type RideFormMixinType };
