import { render, staticRenderFns } from "./Conductors.vue?vue&type=template&id=36a8b508&scoped=true"
import script from "./Conductors.vue?vue&type=script&lang=ts"
export * from "./Conductors.vue?vue&type=script&lang=ts"
import style0 from "./Conductors.vue?vue&type=style&index=0&id=36a8b508&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a8b508",
  null
  
)

export default component.exports