<template>
  <div class="base-data-list flex-grow-1" data-test="base-data-list">
    <v-card class="base-data-list__card d-flex flex-column">
      <v-card-title class="pa-0 d-block">
        <slot name="prepend"></slot>
        <v-text-field :value="textFilter" :label="$t(label)" hide-details clearable @input="setTextFilter">
          <template #append>
            <v-icon small class="mt-1">fal fa-search</v-icon>
          </template>
        </v-text-field>
      </v-card-title>
      <v-list class="base-data-list__items">
        <v-list-item-group @change="$emit('item-selected', $event)">
          <v-list-item
            v-for="item in filteredItems"
            class="overflow-hidden px-6"
            :value="item.id"
            :key="item.id"
            :title="item.name"
            :disabled="item.disabled"
          >
            <div class="text-truncate">{{ item.name }}</div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { contains } from '@/pwa/modules/Assessment/helpers/string';

import type { BaseDataListItem } from '@/pwa/modules/Assessment/types';

export default Vue.extend({
  props: {
    items: {
      type: Array as PropType<BaseDataListItem[]>,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      textFilter: ''
    };
  },
  computed: {
    filteredItems(): BaseDataListItem[] {
      return this.items.filter(item => contains(item.code, this.textFilter) || contains(item.name, this.textFilter));
    }
  },
  methods: {
    setTextFilter(newText: string) {
      this.textFilter = newText ?? '';
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/colors.scss';
@import '@/shared/styles/shadows.scss';

.base-data-list {
  display: grid;
  grid-template-rows: auto 1fr;

  &__card {
    // grow the card with its content but not beyond the parent
    min-height: 0;
    box-shadow: $shadow-xl !important;
  }

  &__items {
    overflow: auto;
  }

  ::v-deep .v-input__slot {
    padding-inline: 24px;

    &::before,
    &::after {
      left: 24px !important;
      width: calc(100% - 48px) !important;
    }
  }
}
</style>
