<template>
  <div class="assessment-base-data d-flex">
    <assessment-base-data-list
      v-if="$vuetify.breakpoint.xs"
      :label="label"
      :items="items"
      @item-selected="emitBaseDataComplete"
    >
      <template #prepend>
        <v-card-actions class="pa-0 pb-4">
          <v-checkbox v-model="specialAssessment" :label="$t('rideAssessment.specialAssessment')" hide-details />
        </v-card-actions>
      </template>
    </assessment-base-data-list>
    <v-card v-else class="assessment-base-data__card pos-absolute pt-2 pb-6">
      <v-btn @click="() => $emit('close')" class="assessment-base-data__close" small icon>
        <v-icon color="grey" small>far fa-times</v-icon>
      </v-btn>
      <v-container>
        <v-row>
          <v-col class="pl-6 pr-4"><app-date-picker v-model="assessmentDateTime" hide-label /></v-col>
          <v-col class="pr-6 pl-4"><app-time-picker v-model="assessmentDateTime" hide-label hide-details /></v-col>
          <v-col cols="12" class="pt-0 pb-2 pl-6 pr-6">
            <v-checkbox v-model="specialAssessment" label="Sondererfassung" hide-details />
          </v-col>
        </v-row>
      </v-container>
      <assessment-base-data-autocomplete :label="label" :items="items" @item-selected="emitBaseDataComplete" />
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import AssessmentBaseDataAutocomplete from '@/pwa/modules/Assessment/components/AssessmentBaseDataAutocomplete.vue';
import AssessmentBaseDataList from '@/pwa/modules/Assessment/components/AssessmentBaseDataList.vue';
import AppDatePicker from '@/shared/modules/Global/components/AppDatePicker.vue';
import AppTimePicker from '@/shared/modules/Global/components/AppTimePicker.vue';
import { formatApiDateTime, parseApiDateTime } from '@/shared/helper/date';

import type { RideAssessmentBaseData, BaseDataListItem } from '@/pwa/modules/Assessment/types';

export default Vue.extend({
  components: { AssessmentBaseDataAutocomplete, AssessmentBaseDataList, AppDatePicker, AppTimePicker },
  props: {
    items: {
      type: Array as PropType<BaseDataListItem[]>,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      baseData: {
        assessmentDateTime: new Date().toISOString(),
        id: '',
        specialAssessment: false
      } as RideAssessmentBaseData
    };
  },
  computed: {
    assessmentDateTime: {
      get(): string {
        return formatApiDateTime(this.baseData.assessmentDateTime);
      },
      set(newDate: string): void {
        const newParsedDate = parseApiDateTime(newDate);
        if (newParsedDate !== undefined) {
          this.baseData.assessmentDateTime = newParsedDate;
        }
      }
    },
    specialAssessment: {
      get(): boolean {
        return this.baseData.specialAssessment;
      },
      set(newSpecialAssessment: boolean): void {
        this.baseData.specialAssessment = newSpecialAssessment;
      }
    }
  },
  methods: {
    emitBaseDataComplete(selectedItemId: string) {
      this.baseData.id = selectedItemId;
      this.$emit('baseDataComplete', this.baseData);
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/shadows.scss';

.assessment-base-data {
  --bottom-navigation-height: calc(56px + env(safe-area-inset-bottom));
  --margin-top: 44px;

  height: calc(100vh - 300px - var(--bottom-navigation-height) - var(--margin-top));
  margin-top: var(--margin-top);
  width: 100%;

  &__card {
    box-shadow: $shadow-xl !important;
    z-index: 1;
  }

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  @include sm-and-up {
    height: auto;
    margin-top: 0;

    &__card {
      margin-top: -200px;
      width: calc(100% - 2 * var(--inline-margin, 0px));
    }
  }
}
</style>
