<template>
  <v-container v-if="stationAssessment" class="pt-0 px-6">
    <app-tabs v-model="activeTab" class="section-tabs mx-n6 mx-md-0 pt-7 pb-3 px-6 px-md-0" vertical-indicator>
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :href="`#${tab.value}`"
        :class="['section-tab', `section-tab--${tab.status}`]"
      >
        <span class="section-tab__label mr-2">
          {{ $t('stationAssessmentEdit.sections.qualityOfStayShort') }} {{ index + 1 }}
        </span>
        <span class="section-tab__status-label font-weight-regular">{{ $t(tab.statusLabel) }}</span>
      </v-tab>
    </app-tabs>
    <v-tabs-items v-model="activeTab" class="transparent">
      <v-tab-item value="qualityOfStayOne" data-test="section-qos1">
        <section-heading
          heading="stationAssessmentEdit.sections.platformsAndAccessAreas"
          subheading="stationAssessmentEdit.sections.cleanlinessAndStructuralCondition"
        />
        <div class="form-grid">
          <devaluation-input
            v-for="devaluationUnit in qualityOfStayOneDevaluationUnits"
            :key="devaluationUnit.key"
            v-bind="devaluationUnit"
            @info-icon-clicked="openInfoModal(devaluationUnit.key)"
            @open-modal="openCameraAndNotesModal(devaluationUnit.key)"
          >
            <range-option v-model="stationAssessment.qualityOfStay[devaluationUnit.key].value" :disabled="readOnly" />
          </devaluation-input>
          <v-btn
            outlined
            color="primary"
            class="navigation-button my-3"
            data-test="button-qos2"
            @click="activeTab = 'qualityOfStayTwo'"
          >
            {{ $t('stationAssessmentEdit.sections.continueToQualityOfStay2') }}
          </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item value="qualityOfStayTwo" data-test="section-qos2">
        <section-heading
          heading="stationAssessmentEdit.sections.platformsAndAccessAreas"
          subheading="stationAssessmentEdit.sections.featuresDamageFree"
        />
        <div class="form-grid">
          <devaluation-input
            v-for="devaluationUnit in qualityOfStayTwoDevaluationUnits"
            :key="devaluationUnit.key"
            v-bind="devaluationUnit"
            @info-icon-clicked="openInfoModal(devaluationUnit.key)"
            @open-modal="openCameraAndNotesModal(devaluationUnit.key)"
          >
            <condition-option
              v-model="stationAssessment.qualityOfStay[devaluationUnit.key].value"
              :disabled="readOnly"
            />
          </devaluation-input>
          <devaluation-input
            title="stationAssessmentEdit.sectionNotes.qualityOfStay"
            :error-message="getSectionNoteErrorMessage()"
            :has-comment="hasComment(stationAssessment.qualityOfStay.note)"
            :has-image="hasImage(stationAssessment.qualityOfStay.images)"
            @open-modal="openCameraAndNotesModal('qualityOfStay')"
          >
            <section-note-input v-model="stationAssessment.qualityOfStay.note" />
          </devaluation-input>
          <v-btn outlined color="primary" class="navigation-button my-3" @click="activeTab = 'qualityOfStayOne'">
            {{ $t('stationAssessmentEdit.sections.returnToQualityOfStay1') }}
          </v-btn>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <assessment-overview-button :complete="qualityOfStayComplete" to-route-name="station-assessment" />
    <devaluation-information-modal
      v-if="currentDevaluationUnit"
      v-model="devaluationInformationModalVisible"
      :title="`stationAssessmentEdit.devaluationUnits.${currentDevaluationUnit}`"
      :text="devaluationUnitInformation[currentDevaluationUnit]"
    />
    <camera-and-notes-modal
      v-if="cameraAndNotesModalDevaluationUnit"
      :devaluation-unit-name="cameraAndNotesModalDevaluationUnit"
      :icon="getImage(cameraAndNotesModalDevaluationUnit)"
      :title="cameraAndNotesModalTitle"
      :images="cameraAndNotesModalImages"
      :note="cameraAndNotesModalNote"
      :read-only="readOnly"
      :download-title="cameraAndNotesDownloadTitle"
      :no-note-character-limit="true"
      @add-images="addImages"
      @add-note="addNote"
      @closed="() => this.setCameraAndNotesModalDevaluationUnit(null)"
      @delete-image="deleteImage"
    />
  </v-container>
</template>

<script lang="ts">
import AppTabs from '@/shared/modules/Global/components/AppTabs.vue';
import Vue, { PropType, VueConstructor } from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import ConditionOption from '@/shared/modules/AssessmentEdit/components/ConditionOption.vue';
import RangeOption from '@/shared/modules/AssessmentEdit/components/RangeOption.vue';
import SectionHeading from '@/shared/modules/AssessmentEdit/components/SectionHeading.vue';
import SectionNoteInput from '@/shared/modules/AssessmentEdit/components/SectionNoteInput.vue';
import DevaluationInput from '@/shared/modules/AssessmentEdit/components/DevaluationInput.vue';
import DevaluationInformationModal from '@/shared/modules/AssessmentEdit/components/DevaluationInformationModal.vue';
import CameraAndNotesModal from '@/shared/modules/AssessmentEdit/components/CameraAndNotesModal.vue';
import AssessmentOverviewButton from '@/shared/modules/AssessmentEdit/components/AssessmentOverviewButton.vue';
import {
  QualityOfStayOneDevaluationUnits,
  QualityOfStayTwoDevaluationUnits,
  DevaluationUnitImages
} from '@/shared/modules/AssessmentEdit/services/station-assessment-statics';
import {
  getNoteErrorMessage,
  getQualityOfStayStatus
} from '@/shared/modules/AssessmentEdit/services/station-assessment-validation';
import { getImages, getImagesFromFileList } from '@/shared/modules/AssessmentEdit/services/image-service';
import { getImageDownloadTitle } from '@/shared/modules/AssessmentEdit/services/station-assessment-service';
import { devaluationUnitInformation } from '@/shared/modules/AssessmentEdit/services/station-devaluation-unit-information';
import { STATION_ASSESSMENT_EDIT_NAMESPACE } from '@/shared/modules/AssessmentEdit/stores/station-assessment-edit';

import type {
  DevaluationUnitKey,
  QualityOfStayDevaluationUnitKey,
  StationAssessmentSectionKey,
  CameraAndNotesModalDevaluationUnit,
  DevaluationUnitInputs,
  StationAssessmentEditState
} from '@/shared/modules/AssessmentEdit/types/station';
import type { AssessmentValidationUnitStatus } from '@/shared/modules/AssessmentEdit/types';

type VuexBindings = {
  stationAssessment: StationAssessmentEditState['stationAssessment'];
  assessmentValidation: StationAssessmentEditState['assessmentValidation'];
  cameraAndNotesModalDevaluationUnit: StationAssessmentEditState['cameraAndNotesModalDevaluationUnit'];
};

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    AppTabs,
    RangeOption,
    SectionHeading,
    ConditionOption,
    SectionNoteInput,
    DevaluationInput,
    DevaluationInformationModal,
    CameraAndNotesModal,
    AssessmentOverviewButton
  },
  data() {
    return {
      activeTab: 'qualityOfStayOne' as StationAssessmentSectionKey,
      devaluationInformationModalVisible: false,
      currentDevaluationUnit: '' as DevaluationUnitKey,
      devaluationUnitInformation
    };
  },
  computed: {
    ...mapState(STATION_ASSESSMENT_EDIT_NAMESPACE, [
      'stationAssessment',
      'assessmentValidation',
      'readOnly',
      'cameraAndNotesModalDevaluationUnit'
    ]),
    qualityOfStayOneStatus(): AssessmentValidationUnitStatus {
      return this.assessmentValidation?.qualityOfStayOne.status ?? 'initial';
    },
    qualityOfStayTwoStatus(): AssessmentValidationUnitStatus {
      return this.assessmentValidation?.qualityOfStayTwo.status ?? 'initial';
    },
    qualityOfStayComplete(): boolean {
      return (
        getQualityOfStayStatus(
          this.assessmentValidation?.qualityOfStayOne.status,
          this.assessmentValidation?.qualityOfStayTwo.status
        ) === 'complete'
      );
    },
    tabs(): { status: AssessmentValidationUnitStatus; statusLabel: string; value: StationAssessmentSectionKey }[] {
      return [
        {
          status: this.qualityOfStayOneStatus,
          statusLabel: this.getStatusLabel(this.qualityOfStayOneStatus),
          value: 'qualityOfStayOne'
        },
        {
          status: this.qualityOfStayTwoStatus,
          statusLabel: this.getStatusLabel(this.qualityOfStayTwoStatus),
          value: 'qualityOfStayTwo'
        }
      ];
    },
    qualityOfStayOneDevaluationUnits(): DevaluationUnitInputs<typeof QualityOfStayOneDevaluationUnits> {
      return QualityOfStayOneDevaluationUnits.map(unitKey => {
        return {
          key: unitKey,
          title: `stationAssessmentEdit.devaluationUnits.${unitKey}`,
          icon: this.getImage(unitKey),
          errorMessage: this.getErrorMessage('qualityOfStayOne', unitKey),
          showInfo: this.devaluationUnitInformation[unitKey] !== undefined,
          hasComment: this.hasComment(this.stationAssessment?.qualityOfStay?.[unitKey]?.note),
          hasImage: this.hasImage(this.stationAssessment?.qualityOfStay?.[unitKey]?.images)
        };
      });
    },
    qualityOfStayTwoDevaluationUnits(): DevaluationUnitInputs<typeof QualityOfStayTwoDevaluationUnits> {
      return QualityOfStayTwoDevaluationUnits.map(unitKey => {
        return {
          key: unitKey,
          title: `stationAssessmentEdit.devaluationUnits.${unitKey}`,
          icon: this.getImage(unitKey),
          errorMessage: this.getErrorMessage('qualityOfStayTwo', unitKey),
          showInfo: this.devaluationUnitInformation[unitKey] !== undefined,
          hasComment: this.hasComment(this.stationAssessment?.qualityOfStay?.[unitKey]?.note),
          hasImage: this.hasImage(this.stationAssessment?.qualityOfStay?.[unitKey]?.images)
        };
      });
    },
    cameraAndNotesModalTitle(): string {
      if (!this.stationAssessment || !this.cameraAndNotesModalDevaluationUnit) {
        return '';
      }

      if ('qualityOfStay' === this.cameraAndNotesModalDevaluationUnit) {
        return `stationAssessmentEdit.sectionNotes.${this.cameraAndNotesModalDevaluationUnit}`;
      }

      return `stationAssessmentEdit.devaluationUnits.${this.cameraAndNotesModalDevaluationUnit}`;
    },
    cameraAndNotesModalNote(): string {
      if (!this.stationAssessment || !this.cameraAndNotesModalDevaluationUnit) {
        return '';
      }

      if ('qualityOfStay' === this.cameraAndNotesModalDevaluationUnit) {
        return this.stationAssessment.qualityOfStay.note;
      }

      // @ts-ignore
      return this.stationAssessment.qualityOfStay[this.cameraAndNotesModalDevaluationUnit].note;
    },
    cameraAndNotesDownloadTitle(): string {
      return getImageDownloadTitle(
        this.stationAssessment?.stationName ?? '',
        this.stationAssessment?.assessmentDateTime ?? '',
        this.$t(this.cameraAndNotesModalTitle).toString()
      );
    }
  },
  asyncComputed: {
    cameraAndNotesModalImages: {
      get(): Promise<string[]> {
        if (!this.stationAssessment || !this.cameraAndNotesModalDevaluationUnit) {
          return Promise.resolve([]);
        }

        if ('qualityOfStay' === this.cameraAndNotesModalDevaluationUnit) {
          return getImages(this.stationAssessment.qualityOfStay.images);
        }

        // @ts-ignore
        return getImages(this.stationAssessment.qualityOfStay[this.cameraAndNotesModalDevaluationUnit].images);
      },
      default: []
    }
  },
  watch: {
    activeTab(newTab, oldTab) {
      this.setSectionVisited(oldTab);
      if (this.$vuetify.breakpoint.xs) {
        window.scrollTo(0, 0);
      }
    }
  },
  methods: {
    ...mapMutations(STATION_ASSESSMENT_EDIT_NAMESPACE, [
      'setStationAssessmentSectionVisited',
      'setCameraAndNotesModalDevaluationUnit'
    ]),
    ...mapActions(STATION_ASSESSMENT_EDIT_NAMESPACE, [
      'addStationAssessmentImages',
      'addStationAssessmentNote',
      'deleteStationAssessmentImage'
    ]),
    getStatusLabel(status: AssessmentValidationUnitStatus) {
      return status === 'complete'
        ? 'stationAssessmentEdit.statuses.complete'
        : 'stationAssessmentEdit.statuses.incomplete';
    },
    getImage(devaluationUnit: QualityOfStayDevaluationUnitKey) {
      return DevaluationUnitImages[devaluationUnit];
    },
    getErrorMessage(
      sectionKey: 'qualityOfStayOne' | 'qualityOfStayTwo',
      devaluationUnit: QualityOfStayDevaluationUnitKey
    ) {
      return this.assessmentValidation?.[sectionKey].units[devaluationUnit]?.errorMessage ?? '';
    },
    hasComment(note: string | undefined): boolean {
      return note !== '';
    },
    hasImage(images: string[] | undefined): boolean {
      return images?.length !== 0;
    },
    getSectionNoteErrorMessage() {
      return getNoteErrorMessage(this.assessmentValidation, 'qualityOfStayTwo');
    },
    openInfoModal(devaluationUnit: QualityOfStayDevaluationUnitKey) {
      this.currentDevaluationUnit = devaluationUnit;
      this.devaluationInformationModalVisible = true;
    },
    setSectionVisited(section?: StationAssessmentSectionKey) {
      this.setStationAssessmentSectionVisited(section ?? this.activeTab);
    },
    setCurrentSectionVisited() {
      this.setSectionVisited();
    },
    openCameraAndNotesModal(unit: CameraAndNotesModalDevaluationUnit) {
      this.setCameraAndNotesModalDevaluationUnit(unit);
    },
    async addImages(event: Event) {
      const images = await getImagesFromFileList(event);
      await this.addStationAssessmentImages({ images, category: 'qualityOfStay' });
    },
    addNote(note: string) {
      this.addStationAssessmentNote({ note, category: 'qualityOfStay' });
    },
    deleteImage(imageIndex: number) {
      this.deleteStationAssessmentImage({ imageIndex, category: 'qualityOfStay' });
    }
  },
  created() {
    window.addEventListener('beforeunload', this.setCurrentSectionVisited);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.setCurrentSectionVisited);
  },
  beforeRouteLeave(to, from, next) {
    this.setSectionVisited();
    next();
  }
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/media.scss';
@import '@/shared/styles/shadows.scss';
@import '@/shared/styles/layout.scss';

.section-tabs {
  --header-height: 50px;

  background-color: white;
  box-shadow: $shadow-xl;
  padding-left: 82px;
  position: sticky;
  top: var(--header-height);
  width: auto;
  z-index: 1;

  @include sm-and-up {
    --header-height: 85px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  @include md-and-up {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid var(--v-white-darken1);
    position: static;
  }

  ::v-deep .v-slide-group__content {
    column-gap: 32px;
  }
}

.section-tab {
  --label-color: var(--v-darkgrey-darken1);

  display: flex;
  align-items: baseline;
  flex-direction: column;

  @include md-and-up {
    flex-direction: row;
  }

  &__label {
    color: var(--label-color);
    font-size: 0.9375rem;
  }

  &__status-label {
    color: var(--v-lightgrey-base);
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: 1.2;

    @include md-and-up {
      font-size: 0.875rem;
    }
  }

  &--complete {
    --label-color: var(--v-primary-base);
  }

  &--incomplete {
    --label-color: var(--v-error-base);
    --tab-indicator-color: var(--v-error-base);
  }

  &.v-tab--active {
    .section-tab__status-label {
      color: inherit;
    }
  }
}

.form-grid {
  @include dynamic-grid(40px, 300px, 3);

  @include md-and-up {
    row-gap: 40px;
    justify-content: start;
  }
}

.navigation-button {
  grid-column: -2;
  justify-self: center;
  letter-spacing: 0;
  min-width: 200px !important;
  text-transform: none;

  @include md-and-up {
    justify-self: end;
  }
}
</style>
