import { render, staticRenderFns } from "./InTrainInformation.vue?vue&type=template&id=fca01fc2&scoped=true"
import script from "./InTrainInformation.vue?vue&type=script&lang=ts"
export * from "./InTrainInformation.vue?vue&type=script&lang=ts"
import style0 from "./InTrainInformation.vue?vue&type=style&index=0&id=fca01fc2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fca01fc2",
  null
  
)

export default component.exports